import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDirections } from '@fortawesome/free-solid-svg-icons'; // Choose an appropriate icon

const StoreModal = ({ stores, isOpen, onClose }) => {
    if (!isOpen) return null;

    const openGoogleMaps = (latitude, longitude) => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="px-4 py-5 sm:p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Bulunduğu Mağazalar</h3>
                        <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="overflow-y-auto max-h-96">
                        {stores.map((store, index) => (
                            <div key={index} className="border-b py-2 flex items-start">
                                <div className="flex-1">
                                    <h4 className="text-sm font-medium text-gray-900">{store.name}</h4>
                                    <p className="text-sm text-gray-500">{store.address}</p>
                                    <p className="text-sm text-gray-500">Stok Durumu: Var</p>
                                    <p className="text-sm text-gray-500">Mesafe: {store.distance.toFixed(2)} km</p>
                                </div>
                                <div className="ml-4 flex items-center">
                                    <button
                                        className="text-brand-blue-primary hover:underline flex items-center"
                                        onClick={() => openGoogleMaps(store.latitude, store.longitude)}
                                    >
                                        <FontAwesomeIcon icon={faDirections} className="w-4 h-4 mr-2" />
                                        Yol Tarifi
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-blue-primary text-base font-medium text-white hover:bg-brand-blue-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue-primary sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={onClose}
                    >
                        Kapat
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StoreModal;
