// Header.js
import React from 'react';
import Logo from './Logo';
import SearchBar from './SearchBar';
import Tabs from "./Tabs";


const Header = ({ searchTerm, onSearchChange, currentTab, onTabChange, tabs, predefinedCategories, onCategoryChange, selectedCategory }) => {
    return (
        <div className="sticky top-0 z-20 bg-white">
            <div className="bg-gradient-to-r from-[#71E6F5] to-brand-blue-primary pt-[10px] pb-[6px] overflow-x-scroll no-scrollbar overflow-y-hidden">
                <div className="w-full px-4 tablet:mx-auto tablet:max-w-screen-tablet tablet:px-5 laptop:max-w-screen-laptop laptop:px-6 desktop:max-w-screen-desktop desktop:px-9">
                    <div className="flex items-center">
                        <Logo />
                        <div className="flex-1"></div>
                        <div className="mobile:w-6 tablet:w-2"></div>
                        <div className="mobile:w-6 tablet:w-2"></div>
                        <div className="mobile:hidden laptop:block">
                        </div>
                        <div className="mobile:w-6 tablet:w-2"></div>
                        <div className="mobile:hidden laptop:block">
                            <div>
                                <div className="bg-brand-gray-background flex items-center rounded-full cursor-pointer tablet:border tablet:border-white/50 tablet:h-12 tablet:w-12 tablet:justify-center laptop:w-auto mobile:p-2 laptop:px-4">
                                    <div>
                                        {/* User icon */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white tablet:bg-brand-gray-background mobile:bg-brand-gray-background" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px 0px' }}>
                <div className="my-[10px] w-full flex items-center">
                    <div className="w-full px-4 tablet:mx-auto tablet:max-w-screen-tablet tablet:px-5 laptop:max-w-screen-laptop laptop:px-6 desktop:max-w-screen-desktop desktop:px-9">
                        <SearchBar searchTerm={searchTerm} onSearchChange={onSearchChange} />
                    </div>
                </div>
                <Tabs currentTab={currentTab} onTabChange={onTabChange} tabs={tabs} />
            </div>

            {predefinedCategories && predefinedCategories.length > 0 && (
                <div className="w-full px-4 tablet:mx-auto tablet:max-w-screen-tablet tablet:px-5 laptop:max-w-screen-laptop laptop:px-6 desktop:max-w-screen-desktop desktop:px-9" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flex h-14 items-center gap-2 overflow-auto whitespace-nowrap">
                        {predefinedCategories.map(filter => (
                            <div
                                key={filter}
                                onClick={() => onCategoryChange(filter)} // Trigger category change on click
                                className={`flex items-center justify-center h-10 px-4 rounded-full w-fit cursor-pointer ${selectedCategory === filter? 'bg-brand-blue-primary text-white' : 'bg-white text-brand-gray-primary border border-brand-gray-border'}`}
                            >
                                {filter}
                            </div>
                        ))}
                    </div>
                </div>
            )}

        </div>
    );
};

export default Header;
