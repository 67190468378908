import React, { useState, useEffect, useRef, memo } from 'react';
import ProductCard from './ProductCard';

const ProductList = memo(({ products }) => {
    const [visibleProducts, setVisibleProducts] = useState([]); // Visible products state
    const [initialBatch, setInitialBatch] = useState(10); // Number of products to show initially
    const productListRef = useRef(null); // Create a ref for the scroll container

    useEffect(() => {
        // First, render the initial batch of products
        setVisibleProducts(products.slice(0, initialBatch));

        // After a delay, render the rest of the products
        const timeoutId = setTimeout(() => {
            setVisibleProducts(products); // Show all products after the delay
        }, 200); // Adjust the delay

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, [products, initialBatch]);

    // Scroll to the top of the container whenever products are updated
    useEffect(() => {
        if (productListRef.current) {
            productListRef.current.scrollTo({ top: 0 }); // Scroll smoothly to the top
        }
    }, [products]); // Runs whenever the products array is updated (i.e., when tab or category changes)

    return (
        <div
            ref={productListRef} // Attach the ref to the container
            className="grid grid-cols-2 gap-4 overflow-y-auto max-h-screen p-4"
        >
            {visibleProducts.map(product => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    );
});

export default ProductList;
