import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchBar = ({ searchTerm, onSearchChange }) => {
    return (
        <div className="mobile:block  w-full border-b sticky top-0 z-10 bg-white tablet:bg-brand-gray-background mobile:bg-brand-gray-background" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 4px 0px' }}>
            <div className="my-[10px] w-full flex items-center">
                <div className="w-full px-4 tablet:mx-auto tablet:max-w-screen-tablet tablet:px-5 laptop:max-w-screen-laptop laptop:px-6 desktop:max-w-screen-desktop desktop:px-9">
                    <div className="relative flex-1">
                        <form>
                            <input
                                id="onlineSearchBar"
                                className="text-center outline-none caret-brand-blue-primary ring-0 pl-4 pr-10 focus:!ring-brand-gray-border focus:ring-0 focus:border-brand-gray-border w-full bg-white items-center h-10 tablet:h-12 rounded-full focus:placeholder:text-white cursor-pointer border border-brand-gray-border placeholder:text-brand-gray-secondary placeholder:text-base !pr-1 mobile:placeholder:!text-[15px]"
                                placeholder="Aramak istediğin ürünü yaz..."
                                autocomplete="off"
                                value={searchTerm}
                                onChange={onSearchChange}
                            />
                        </form>
                        <div className="absolute mobile:left-3 laptop:left-4 top-1/2 -translate-y-1/2">
                            <FaSearch className="text-gray-500" />
                        </div>
                        <div className="absolute right-5 top-1/2 -translate-y-1/2"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
