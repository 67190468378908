import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from './axiosConfig'; // Import the axios instance
import Header from './Header';
import ProductList from './ProductList';

const App = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentTab, setCurrentTab] = useState('');
    const [tabs, setTabs] = useState([]);
    const [products, setProducts] = useState([]);
    const [predefinedCategories, setPredefinedCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(''); // Track selected category
    // Disable scroll on body when ProductList is active
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable body scroll
        return () => {
            document.body.style.overflow = ''; // Reset body scroll when unmounted
        };
    }, []); // Runs on component mount and cleanup
    useEffect(() => {
        // Fetch active catalogs
        axiosInstance.get('/api/catalogs/active')
            .then(response => {
                const activeCatalogs = response.data;
                setTabs(activeCatalogs);
                if (activeCatalogs.length > 0) {
                    setCurrentTab(activeCatalogs[0].id);
                }
            })
            .catch(error => {
                console.error('Error fetching active catalogs:', error);
            });
    }, []);

    useEffect(() => {
        if (currentTab) {
            // Fetch products for the current tab
            axiosInstance.get(`/api/catalogs/${currentTab}/products`)
                .then(response => {
                    setProducts(response.data.products);
                    setPredefinedCategories(response.data.predefinedCategories)
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                });
        }
    }, [currentTab]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        setSelectedCategory(undefined)
    };
    // Handle category filter click
    const handleCategoryChange = (category) => {
        setSelectedCategory(category); // Set the selected category
    };

    const filteredProducts = useMemo(() => {

        if(!products){
            return true
        }

        return products.filter(product => {
            // If no category is selected and no search term is entered, return all products
            if (!selectedCategory && !searchTerm) {
                return true; // Return all products
            }

            // Otherwise, filter by selected category and search term
            const matchesCategory = selectedCategory ? product.category === selectedCategory : true;
            const matchesSearch = searchTerm ? product.name.toLowerCase().includes(searchTerm.toLowerCase()) : true;

            return matchesCategory && matchesSearch;
        });
    }, [products, selectedCategory, searchTerm]); // Dependencies: products, selectedCategory, and searchTerm


    return (
        <div className="App">
            <Header
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                currentTab={currentTab}
                onTabChange={handleTabChange}
                tabs={tabs}
                predefinedCategories={predefinedCategories}
                onCategoryChange={handleCategoryChange} // Pass the handler to Header
                selectedCategory={selectedCategory}
            />
            <ProductList searchTerm={searchTerm} products={filteredProducts} />
        </div>
    );
};

export default App;
