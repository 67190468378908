// Tabs.js
import React from 'react';

const Tabs = ({ currentTab, onTabChange, tabs }) => {
    return (
        <div className="bg-white border-b sticky top-0 z-10">
            <div className="flex justify-center space-x-4">
                {tabs.map(tab => (
                    <button
                        key={tab.id}
                        className={`py-2 px-4 ${currentTab === tab.id ? 'border-b-2 border-brand-blue-primary text-brand-blue-primary' : 'text-gray-600'}`}
                        onClick={() => onTabChange(tab.id)}
                    >
                        {tab.catalogName}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
