import React from 'react';

const Logo = () => {
    return (
        <div className="z-20 relative">
            <a
                title="A101 Kurumsal"
                className="cursor-pointer bg-brand-blue-secondary flex justify-center items-center rounded-md mobile:px-4 laptop:!px-8 tablet:!px-10 mobile:h-11 tablet:h-[52px] w-[125px] tablet:w-[160px]"
                href="/"
            >
                <div className="select-none relative w-full h-full">
                    <div className="transition-opacity duration-300 aspect-[70/26] w-full h-full flex items-center justify-center">
                        <img
                            draggable="false"
                            className="scale-x-100"
                            src="https://rio.a101.com.tr/dbmk89vnr/CALL/Image/get/a101-logo-2_256x256.svg"
                            width="93"
                            height="26"
                            loading="lazy"
                            fetchpriority="auto"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </a>
        </div>
    );
};

export default Logo;
